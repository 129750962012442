import * as React from "react"
import Layout from "../layouts/default"
import * as style from "../sass/pages/home.module.sass"
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'


export default function Home({ data }) {
  const document = data.allSanityHome.edges[0].node;
  return (
    <Layout
      title="Home">
      <header className={style.header}>
        <div>
          <h1>{document.title}</h1>
          <h2 className="h6">{document.subtitle}</h2>
          <a className="btn-primary btn-extended" href="#showcase">Listen</a>
        </div>
        <div className={style.heroFeed}>
          <img alt="Cover Art" src="https://soapbox.house/api/getPodcastArtwork?id=H4JIt7q9e6KBk74pHwpo"/>
          <img alt="Cover Art" src="https://soapbox.house/api/getPodcastArtwork?id=S3gXZLrEkzD8XmnTq0n1"/>
          <img alt="Cover Art" src="https://soapbox.house/api/getPodcastArtwork?id=5wxmufHQQEkfwiyjvOek"/>
          <img alt="Cover Art" src="https://themidnightexchange.com/images/channel/artwork.jpg"/>
        </div>
      </header>
      <section id="showcase" className={style.networkShowcase}>
        <h1 className="h2">{document.networkTitle}</h1>
        <h2 className="h6">{document.networkSubtitle}</h2>
        <div className={style.podcastGrid}>
          { 
            document.podcastList.map((podcast, index) => (
              <article className={style.podcastGridItem} key={index}>
                <div>
                  <GatsbyImage className={style.image} image={podcast.image.asset.gatsbyImageData} alt={podcast.image.alt} />
                  <h6>{podcast.podcastTitle}</h6>
                  <p className="body2">{podcast.podcastDescription}</p>
                </div>
                <div className={style.buttonsWrapper}>
                  <a target="_blank" rel="noreferrer noopener" href={podcast.spotifyUrl} aria-label="Spotify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path id="spotify-brands" d="M10,8A10,10,0,1,0,20,18,10,10,0,0,0,10,8Zm4.06,14.714a.769.769,0,0,1-.431-.145,11.381,11.381,0,0,0-8.335-.988,2.6,2.6,0,0,1-.48.1.628.628,0,0,1-.637-.637.639.639,0,0,1,.548-.677c3.3-.73,6.677-.665,9.556,1.056a.682.682,0,0,1,.391.665.609.609,0,0,1-.613.621Zm1.085-2.645a1,1,0,0,1-.5-.169,13.067,13.067,0,0,0-9.621-1.185,1.661,1.661,0,0,1-.48.1.783.783,0,0,1-.782-.782.793.793,0,0,1,.625-.835,13.563,13.563,0,0,1,3.944-.548,14.115,14.115,0,0,1,7.137,1.835.841.841,0,0,1,.456.794A.781.781,0,0,1,15.145,20.069ZM16.4,17a.927.927,0,0,1-.52-.157c-2.871-1.714-8-2.125-11.327-1.2a1.915,1.915,0,0,1-.52.1.931.931,0,0,1-.94-.952.953.953,0,0,1,.7-.964,16.9,16.9,0,0,1,4.738-.613,17.03,17.03,0,0,1,8.282,1.927.947.947,0,0,1,.52.911A.934.934,0,0,1,16.4,17Z" transform="translate(0 -8)"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href={podcast.appleUrl} aria-label="Apple">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20">
                      <path id="podcast-solid" d="M8.75,0A8.749,8.749,0,0,0,5.289,16.785a20.894,20.894,0,0,1-.273-2.271,6.873,6.873,0,1,1,7.469,0,20.985,20.985,0,0,1-.275,2.272A8.751,8.751,0,0,0,8.75,0Zm0,12.188c-1.284,0-2.5.337-2.5,1.709a28.186,28.186,0,0,0,.805,5.188c.2.744.956.916,1.695.916s1.494-.171,1.695-.913A28.445,28.445,0,0,0,11.25,13.9C11.25,12.523,10.035,12.188,8.75,12.188Zm0-1.25A2.188,2.188,0,1,0,6.563,8.75,2.186,2.186,0,0,0,8.75,10.938ZM14.375,8.75A5.625,5.625,0,1,0,5.2,13.078a2.514,2.514,0,0,1,1.252-1.369s.012,0,.02,0a3.75,3.75,0,1,1,4.564,0,.111.111,0,0,1,.019,0A2.511,2.511,0,0,1,12.3,13.078,5.588,5.588,0,0,0,14.375,8.75Z"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href={podcast.googleUrl} aria-label="Google">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.677" height="20" viewBox="0 0 19.677 20">
                      <path id="google-brands" d="M19.677,18.234C19.677,23.94,15.77,28,10,28A10,10,0,0,1,10,8a9.617,9.617,0,0,1,6.706,2.617l-2.722,2.617C10.423,9.8,3.8,12.379,3.8,18A6.264,6.264,0,0,0,10,24.315,5.407,5.407,0,0,0,15.677,20H10v-3.44h9.52A8.767,8.767,0,0,1,19.677,18.234Z" transform="translate(0 -8)"/>
                    </svg>
                  </a>
                </div>
              </article>
            ))
          }
        </div>
        <a className="btn-primary btn-extended" href="mailto:contact@soapbox.house">Join Our Network</a>
      </section>
    </Layout>
  )
}


export const query = graphql`{
  allSanityHome {
    edges {
      node {
        title
        subtitle
        networkTitle
        networkSubtitle
        podcastList {
          podcastTitle
          podcastDescription
          spotifyUrl
          appleUrl
          googleUrl
          _key
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 600)
            }
          }
        }
      }
    }
  }
}`